var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-4 px-4"},[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Agregar Reserva de prueba ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sumbitBooking($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Reserva de prueba")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"BookedTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.postData.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.postData, "time", $event)},"update:return-value":function($event){return _vm.$set(_vm.postData, "time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"label":"Hora de la reserva","readonly":"","required":"","outlined":""},model:{value:(_vm.postData.time),callback:function ($$v) {_vm.$set(_vm.postData, "time", $$v)},expression:"postData.time"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.postData.time)}},model:{value:(_vm.postData.time),callback:function ($$v) {_vm.$set(_vm.postData, "time", $$v)},expression:"postData.time"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"BookedAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de reserva","readonly":"","placeholder":"YYYY/MM/DD","outlined":""},model:{value:(_vm.postData.date),callback:function ($$v) {_vm.$set(_vm.postData, "date", $$v)},expression:"postData.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":new Date().toISOString().substr(0, 10)},on:{"change":_vm.save},model:{value:(_vm.postData.date),callback:function ($$v) {_vm.$set(_vm.postData, "date", $$v)},expression:"postData.date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"PromotionalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Código promocional","placeholder":"Código promocional","outlined":""},model:{value:(_vm.postData.promoCode),callback:function ($$v) {_vm.$set(_vm.postData, "promoCode", $$v)},expression:"postData.promoCode"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelButton}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }