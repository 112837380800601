import { ServiceFactory } from '@/services/ServiceFactory'
import ZbNewBookingModal from './new-booking-modal'
const _bookingsService = ServiceFactory.get('BookingsService')
export default {
  components: {
    ZbNewBookingModal
  },
  data () {
    return {
      loadingTable: true,
      bookingsActive: {
        bookings: [
          // {
          //   contact: {
          //     name: 'Reserbar-Test'
          //   },
          //   date: '2022-11-08',
          //   time: '14:00',
          //   code: '4811',
          //   meta: null,
          //   numberPeople: 2,
          //   discount: 0.2
          // },
          // {
          //   contact: {
          //     name: 'Reserbar-Test'
          //   },
          //   date: '2022-11-07',
          //   time: '15:30',
          //   code: '9810',
          //   meta: null,
          //   numberPeople: 2,
          //   discount: 0.2
          // }
        ]
      },
      headers: [
        {
          text: 'Imagen',
          value: 'image',
          sortable: false
        },
        {
          text: 'Nombre',
          value: 'contact.name',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'date',
          sortable: false
        },
        {
          text: 'Hora',
          value: 'time',
          sortable: false
        },
        {
          text: 'Cód. reserva',
          value: 'code',
          sortable: false
        },
        {
          text: 'Cód promocional',
          value: 'meta.promoCode',
          sortable: false
        },
        {
          text: 'Cant. personas',
          value: 'numberPeople',
          sortable: false
        },
        {
          text: 'Descuento',
          value: 'discount',
          sortable: false
        },
        {
          text: 'Info',
          value: 'info',
          sortable: false
        }
      ],
      schedules: [
        '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
        '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
        '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59', '00:00', '00:30',
        '01:00', '01:30', '02:00', '02:30', '03:00'
      ],
      postBooking: {
        clientName: '',
        numberPeople: 0,
        date: '',
        time: '',
        comments: ''
      },
      page: 1,
      showNewBookingModal: false,
      menu: false,
      dialog: false,
      menu2: false,
      loading: false,
      zbBarId: ''
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        this.zbBarId = this.$route.params.id
        // console.log(this.$route.params.id, 'booking active component')
        try {
          // this.barData = await _barsService.show(this.$route.params.id)
          this.bookingsActive = await _bookingsService.index(this.$route.params.id, 1, 'active', null)
          this.loadingTable = false
          // console.log(this.bookingsActive)
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las reservas del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
          this.loadingTable = false
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    editCategory (id) {
      // console.log(id)
      this.$router.push({ name: 'member-form', params: { id } })
    },
    destroyCategory (id) {
      this.$swal({
        title: '¿Está seguro de eliminar la categoria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // await _newsCategoriesService.destroy(id)
            this.$swal(
              'Categoría eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    goNewForm (id, payload = null) {
      // console.log(id)
      this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    },
    showModal () {
      if (this.showNewBookingModal === false) {
        this.showNewBookingModal = true
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    async sumbitBooking () {
      this.loading = true
      // console.log('guardar reserva')
      // console.log(this.postBooking)
      if (this.$route.params.id) {
        try {
          this.postBooking.numberPeople = parseInt(this.postBooking.numberPeople)
          await _bookingsService.create(this.$route.params.id, this.postBooking)
          this.dialog = false
          this.$notify({
            type: 'success',
            text: 'Datos modificados correctamente'
          })
          this.loading = false
          this.initialize()
          // this.$router.push({ name: 'companies' })
          // console.log(this.modules.modules[0])
        } catch (error) {
          this.$notify({
            type: 'error',
            text: error.msg
          })
          this.loading = false
          this.disabled = false
          // console.info(error)
        }
      } else {
        this.$notify({
          type: 'error',
          text: 'Sin datos del negocio'
        })
      }
    }
  }
}
