import ZbBookingActiveComponent from './tab-bookings-active'
import ZbBookingCancelledComponent from './tab-bookings-cancelled'
import ZbBookingCompletedComponent from './tab-bookings-completed'
import ZbBookingsReviews from './tab-bookings-reviews'
import ZbBookingNoShow from './tab-bookings-no-show'
export default {
  components: {
    ZbBookingActiveComponent,
    ZbBookingCancelledComponent,
    ZbBookingCompletedComponent,
    ZbBookingsReviews,
    ZbBookingNoShow
  },
  data () {
    return {
      tab: null,
      items: [
        'Activas',
        'Completadas',
        'Canceladas',
        'No asistidas',
        'Reseñas'
      ]
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      // console.log(this.$route)
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        // const id = this.$route.params.id
        // this.$router.push({ name: 'booking', params: { id } })
      } else {
        // console.log('<----------no id-------------->')
        this.$router.push({ name: 'companies' })
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
