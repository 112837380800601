import { ServiceFactory } from '@/services/ServiceFactory'
const _bookingsService = ServiceFactory.get('BookingsService')
export default {
  data  () {
    return {
      loadingButton: false,
      loadingData: false,
      loadingSchedules: false,
      scheduleAvailables: [],
      maxNumberPeople: 0,
      enableDates: [],
      timeList: null,
      showSchedules: false,
      availableDays: [],
      schedule: [],
      dialog: false,
      postData: {
        time: null,
        date: null,
        zbBarId: '',
        promoCode: ''
      },
      menu: false,
      menu2: false,
      loading: false
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.menu.activePicker = 'YEAR'))
    }
  },
  props: {
    zbBarId: String
  },
  methods: {
    intialize () {
      // console.log('modal')
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    cancelButton () {
      this.dialog = false
      this.postData = {
        time: null,
        date: null,
        zbBarId: '',
        promoCode: ''
      }
    },
    async sumbitBooking () {
      this.loading = true
      // console.log('guardar reserva')
      this.postData.zbBarId = this.zbBarId
      console.log(this.postData)
      // if (this.$route.params.id) {
      try {
        // this.postBooking.numberPeople = parseInt(this.postBooking.numberPeople)
        await _bookingsService.create(this.postData)
        this.dialog = false
        this.$notify({
          type: 'success',
          text: 'Reserva registrada correctamente'
        })
        this.loading = false
        this.$emit('updatetookingtable')
        // this.initialize()
        // this.$router.push({ name: 'companies' })
        // console.log(this.modules.modules[0])
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.dialog = false
        this.loading = false
        // this.disabled = false
        // console.info(error)
      }
      // } else {
      //   this.$notify({
      //     type: 'error',
      //     text: 'Sin datos del negocio'
      //   })
      // }
    }
    // getDetailDate () {
    //   this.loadingData = true
    //   BookingService.getAdminSettings()
    //     .then((resp) => {
    //       this.availableDays = resp.bookingSettings.schedule.map((v) => {
    //         return v.dayNumber
    //       })
    //       const auxCurrentDate = moment().format('YYYY-MM-DD')
    //       const auxEndDate = moment().add(7, 'days')
    //       this.enableDates = this.getRangeDates(auxCurrentDate, auxEndDate)
    //       this.maxNumberPeople = resp.bookingSettings.maxPeoplePerBooking
    //       this.loadingData = false
    //     })
    //     .catch((err) => {
    //       this.$notify({
    //         title: 'Error:',
    //         type: 'error',
    //         text: err.msg
    //       })
    //     })
    // },
    // allowedDates (val) {
    //   return _.includes(this.enableDates, val) && _.includes(this.availableDays, new Date(val).getDay())
    // },
    // dialogEvent () {
    //   this.$emit('input')
    // },
    // saveSchedule () {
    //   this.$v.$touch()
    //   this.loadingButton = true
    //   if (!this.$v.$invalid) {
    //     BookingService.saveSchedule(this.schedule)
    //       .then((resp) => {
    //         this.$notify({
    //           type: 'success',
    //           text: resp.msg
    //         })
    //         this.loadingButton = false
    //         this.$emit('input')
    //         this.$emit('closeSchedule', true)
    //       }).catch((err) => {
    //         this.$notify({
    //           title: 'Error:',
    //           type: 'error',
    //           text: err.msg
    //         })
    //         this.loadingButton = false
    //         this.dialogEvent()
    //       })
    //   } else {
    //     this.loadingButton = false
    //   }
    // }
  }
}
