import { render, staticRenderFns } from "./ZbBookingCancelledComponent.html?vue&type=template&id=6f2cd833&"
import script from "./ZbBookingCancelledComponent.js?vue&type=script&lang=js&"
export * from "./ZbBookingCancelledComponent.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports