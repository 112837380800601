import { ServiceFactory } from '@/services/ServiceFactory'
const _bookingsService = ServiceFactory.get('BookingsService')
// const _barMembersService = ServiceFactory.get('BarMembersService')
export default {
  data () {
    return {
      loadingTable: true,
      bookingsReviews: [],
      headers: [
        {
          text: 'Comentarios',
          value: 'text',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Rating',
          value: 'avg',
          sortable: false
        }
      ],
      // barState: true,
      page: 1
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id, 'Booking completed component')
        try {
          this.bookingsReviews = await _bookingsService.reviews(this.$route.params.id)
          this.loadingTable = false
          console.log(this.bookingsReviews)
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las reseñas del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
          this.loadingTable = false
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    }
    // editCategory (id) {
    //   // console.log(id)
    //   this.$router.push({ name: 'member-form', params: { id } })
    // },
    // destroyCategory (id) {
    //   this.$swal({
    //     title: '¿Está seguro de eliminar la categoria?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí, eliminar',
    //     cancelButtonText: 'Cancelar'
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       try {
    //         // await _newsCategoriesService.destroy(id)
    //         this.$swal(
    //           'Categoría eliminada',
    //           'Se eliminó correctamente',
    //           'success'
    //         ).then(async (result) => {
    //           this.initialize()
    //         })
    //       } catch (error) {
    //         this.$notify({
    //           title: 'Error:',
    //           type: 'error',
    //           text: error.message + ', no se pudo eliminar.'
    //         })
    //       }
    //     }
    //   })
    // },
    // goNewForm (id, payload = null) {
    //   // console.log(id)
    //   this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    // }
  }
}
